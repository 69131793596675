import React, {useState,  useEffect} from 'react';
import {string, arrayOf, any} from "prop-types";
import SolutionCard from "./EventCards";
import useWindowSize from "../../helpers";

const WhatWeDoCards = (props) => {
    const {className, solutionsArray} = props
    const [setSize, setWindowSize] = useState(4);
    const size = useWindowSize();

    useEffect(() => {
        const { width } = size;
        const isMobile = width >= 0 && width < 1024;
        const isDesktop = width > 1024;

        const slides = () => {
            if (isMobile) return true;
            if (isDesktop) return false
            return false;
        };
        setWindowSize(slides);
    }, [size]);

    return (
        <div  className="lg:px-[162px] pb-0 md:px-8 px-[16px] mt:24 pb-0 max-w-[1440px] 2xl:mx-auto">
            {!setSize ? (
                <div className='grid lg:grid-cols-2 sm:grid-cols-1 xl:gap-[164px] lg:gap-16 gap-0'>

                    <div>
                        {solutionsArray.map((event, idx) => (
                            idx % 2 === 0 ? (
                                <SolutionCard {...event} idx={idx} key={`what_we_do_${event.anchor}`} />
                            ) : null
                        ))}
                    </div>
                    <div className='md:mt-24'>
                        {solutionsArray.map((event, idx) => (
                            idx % 2 !== 0 ? (
                                <SolutionCard {...event} idx={idx} key={`what_we_do_${event.anchor}`} />
                            ) : null
                        ))}
                    </div>
                </div>

            ) : (
                <div className='grid lg:grid-cols-2 sm:grid-cols-1 xl:gap-[164px] md:gap-16 gap-0'>
                    {
                        solutionsArray.map((event, idx) => (
                            <SolutionCard {...event} idx={idx} key={`what_we_do_${event.anchor}`} />
                        ))
                    }
                </div>
            )}
        </div>
    );
};
WhatWeDoCards.propTypes = {
    className: string,
    solutionsArray: arrayOf(any),
};

WhatWeDoCards.defaultProps = {
    className: '',
    solutionsArray: [],
};

export default WhatWeDoCards;
