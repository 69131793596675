import React, {useState, useEffect} from 'react';
import {string, arrayOf, any} from "prop-types";
import Title from "../Title";
import Carousel from 'nuka-carousel';
import UsSlide from "./UsSlide";
import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import useWindowSize from "../../helpers";

const UsSlider = (props) => {
    const {teammates} = props
    const [numberOfSlides, setNumberOfSlides] = useState(4);
    const size = useWindowSize();

    useEffect(() => {
        const { width } = size;
        const isMobile = width <= 576;
        const isTablet = width > 576 && width < 992;
        const isSreenLG = width > 992 && width < 1200;
        const screenXL = width > 1200 && width <= 1700;
        const screenXXL = width > 1700;

        const slides = () => {
            if (isMobile) return 1.3;
            if (isTablet) return 2;
            if (isSreenLG) return 3;
            if (screenXL) return 4;
            if (screenXXL) return 4;
            return 4;
        };
        setNumberOfSlides(slides);
    }, [size]);
    return (
        <div className='lg:px-[160px] max-w-[1440px] 2xl:mx-auto md:my-32 my-8 px-0'>
            <Title className='text-center text-[42px] leading-[140%]' level={2} withBlueSymbol symbol='.'>Get to know us</Title>
            <Carousel
                slidesToShow={numberOfSlides}
                cellSpacing={16}
                enableKeyboardControls
                dragging
                wrapAround
                cellAlign='center'
                renderBottomCenterControls={() => null}
                renderCenterLeftControls={({ previousSlide }) => (
                    <ArrowLeft onClick={previousSlide} />
                )}
                renderCenterRightControls={({ nextSlide }) => (
                    <ArrowRight onClick={nextSlide} />
                )}
            >
                {teammates.map((teammate, idx) => (
                    <UsSlide {...teammate} key={idx} />
                ))}
            </Carousel>
        </div>
    );
};
UsSlider.propTypes = {
    className: string,
    teammates: arrayOf(any)
};

UsSlider.defaultProps = {
    className: '',
    teammates: []
};

export default UsSlider;
