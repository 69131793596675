import React from 'react';
import {string, bool, oneOf, objectOf, any} from "prop-types";
import Image from "../ImageQueries/GeneralImages";
import Title from "../Title";

const UsSlide = (props) => {
    const {img, teamMate, matePosition} = props
    return (
        <div className='w-full'>
            <Image imageName={img} className='rounded-[32px]' />
            <Title level={4} className='mb-0 text-[16px] leading-[130%]' style={{ marginBottom: 0}}>{teamMate}</Title>
            <p className='font-sans text-black-300 text-[14px] leading-[130%] mt-[8px]'>{matePosition}</p>
        </div>
    );
};
UsSlide.propTypes = {
    img: objectOf(any),
    teamMate: string,
    matePosition: string,

};

UsSlide.defaultProps = {
    img: '',
    teamMate: '',
    matePosition: '',
};

export default UsSlide;
