import * as React from 'react';
import Layout from '../Components/Layout';
import HeadingDirective from '../Components/HeadingDirective';
import Offices from '../Components/Offices';
import Teams from '../Components/Teams';
import Solutions from '../Components/Solutions';
import UsSlider from '../Components/UsSlider';
import { graphql } from 'gatsby';
import SEO from '../Components/SEO';

const WhatWeDo = ({ data }) => {
    const { frontmatter } = data.markdownRemark;

    return (
        <Layout>
            <SEO
                title={frontmatter.seo.title}
                description={frontmatter.seo.description}
                image={`https://pl.spoton.com/assets/${frontmatter.seo.image}`}
            />
            <HeadingDirective
                className="pb-[80px]"
                showCircle
                imgClass="top-[407px] w-[180px]"
                {...frontmatter.directive}
            />
            <Solutions solutionsArray={frontmatter.areas} />
            <UsSlider teammates={frontmatter.teammates} />
            <Teams presentation={frontmatter.teams} />
            {/*<Offices imageArray={frontmatter.imageArray}/>*/}
        </Layout>
    );
};

export default WhatWeDo;

export const pageQuery = graphql`
    query WhatWeDo {
        markdownRemark(frontmatter: { templateKey: { eq: "what-we-do" } }) {
            frontmatter {
                directive {
                    directive
                    symbol
                    subtext
                }
                seo {
                    title
                    description
                    image
                }
                hero {
                    heroTitle
                    heroSubtext
                    heroImage {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
                teammates {
                    teamMate
                    matePosition
                    img {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
                areas {
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                    solutionName
                    symbol
                    anchor
                    teamLead
                    teamPosition
                    solutionDescription
                }
                imageArray {
                    img {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
                teams {
                    teamName
                    teamAbout
                    stack
                    teamMate
                    matePosition
                    img {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
            }
        }
    }
`;
