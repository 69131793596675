import React from "react";
import PropTypes from "prop-types";

const ArrowLeft = ({ onClick }) => (
    <svg
        className="arrow-left md:translate-x-[150px] translate-x-[10px] lg:translate-x-[-30px]  translate-y-[-40px] w-[64px] h-[64px]"
        onClick={onClick}
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            width="64"
            height="64"
            rx="32"
            transform="matrix(-1 0 0 1 64 -0.000488281)"
            fill="#D1E1FF"
            fillOpacity="1"
        />
        <path
            d="M26.6289 32.48C26.4141 32.3081 26.2852 32.0503 26.2852 31.7495C26.2852 31.4917 26.4141 31.2339 26.6289 31.019L34.9648 22.6831C35.1797 22.5112 35.4375 22.3823 35.6953 22.3823C35.9961 22.3823 36.2539 22.5112 36.4258 22.6831L37.3711 23.6284C37.5859 23.8433 37.7148 24.1011 37.7148 24.3589C37.7148 24.6597 37.6289 24.9175 37.4141 25.0894L30.7539 31.7495L37.4141 38.4097C37.6289 38.6245 37.7148 38.8823 37.7148 39.1401C37.7148 39.4409 37.5859 39.6987 37.3711 39.8706L36.4258 40.8159C36.2539 41.0308 35.9961 41.1167 35.6953 41.1167C35.4375 41.1167 35.1797 41.0308 34.9648 40.8159L26.6289 32.48Z"
            fill="#1769FF"
        />
    </svg>
);

ArrowLeft.propTypes = {
    onClick: PropTypes.func,
};
ArrowLeft.defaultProps = {
    onClick: null,
};

export default ArrowLeft;
