import React from 'react';
import {string, bool, objectOf, any} from "prop-types";
import Title from "../Title";
import Image from "../ImageQueries/GeneralImages";

const SolutionCard = (props) => {
    const {solutionName, solutionDescription, teamLead, teamPosition, image, idx, anchor, className} = props
    return (
        <div id={anchor} className={`mb-24 md:mb-4 lg:mb-24 ${className}`}>
            <Image imageName={image} className='rounded-[32px]' />
            <Title level={3} className='text-[24px] leading-[130%] mb-[16px]'  style={{ marginBottom: 16}} symbol='.' withBlueSymbol>{solutionName}</Title>
            <Title level={4} className='text-[18px] leading-[130%] mt-[0px]' style={{marginBottom: 0}}>{teamLead}</Title>
            <p className='font-sans text-black-200 mb-[16px] mt-0 text-[16px] leading-[130%]'>{teamPosition}</p>
            {solutionDescription.split('\n').map((item,idx) => (
                <p key={`item_${idx.toString()}_for_${solutionName}`} className='font-sans text-black-200 xl:max-w-[600px] text-[16px] leading-[160%]'>{item}</p>
            ))}
        </div>
    );
};
SolutionCard.propTypes = {
    solutionName: string,
    solutionDescription: string,
    img: string,
    className: string,
    teamLead: string,
    teamPosition: string,
    anchor: string,
};

SolutionCard.defaultProps = {
    solutionName: '',
    solutionDescription: '',
    img: '',
    teamLead: '',
    teamPosition: '',
    anchor: '',
    className: ''
};

export default SolutionCard;
